import { Vue, Component } from 'vue-property-decorator';
import BCardCode from '@core/components/b-card-code/BCardCode.vue';
import {
  BAvatar,
  BPagination,
  BFormGroup,
  BFormInput,
  BFormSelect,
  BRow,
  BCol,
  BDropdown,
  BDropdownItem,
  BCard,
  BCardHeader,
  BCardBody,
  BButton,
  BModal,
  BForm,
  BCardText,
  BImg,
  BBadge,
  BLink,
  BFormDatepicker
} from 'bootstrap-vue';
import { VueGoodTable } from 'vue-good-table';
import { ListVue, listVueMixin } from '@/utility/pagination/list-vue.mixin';
import Pagination from '@/@core/components/paginations/Pagination.vue';
import { UpdateCustomerLevelInput, CustomerViewModel } from '@/api/api';
import {
  customerListDispatcher,
  CustomerListInput,
  customerListNamespace
} from './store/customer-list.module';
import {
  ListGetter,
  ListGetterTypes
} from '@/utility/pagination/get-list.module-type';
import { DEFAULT_PAGE_SIZE } from '@/utility/pagination/pagination';
import { CustomerLevel } from '@/api/api';
import { DropdownOption } from '@/utility/dropdowns/dropdownOptions';
import { enumToDropdownOptions, StringFormat } from '@/utility/utils';
import router from '@/router';
import { formatCurrency, formatDate } from '@/utility/utils';
import vSelect from 'vue-select';
import ApiClientFactory from '@/api/apiClientFactory';
import EditCustomerModal from './EditCustomerModal.vue';
@Component({
  components: {
    BCard,
    BCardHeader,
    BCardBody,
    BCardCode,
    VueGoodTable,
    BAvatar,
    BPagination,
    BFormGroup,
    BFormInput,
    BFormSelect,
    BRow,
    BCol,
    BDropdown,
    BDropdownItem,
    BButton,
    BModal,
    BForm,
    BCardText,
    Pagination,
    BImg,
    BBadge,
    BLink,
    vSelect,
    BFormDatepicker,
    EditCustomerModal
  },
  mixins: [
    listVueMixin({
      initialInput: {
        itemsPerPage: DEFAULT_PAGE_SIZE,
        searchText: '',
        filterLevel: [] as DropdownOption[]
      },
      dispatcher: customerListDispatcher,
      debouncedWatchers: [(listInput) => listInput.searchText]
    })
  ]
})
export default class CustomerList
  extends Vue
  implements ListVue<CustomerViewModel, CustomerListInput> {
  @customerListNamespace.Getter(ListGetter.state)
  readonly listState!: ListGetterTypes<
    CustomerViewModel,
    CustomerListInput
  >[ListGetter.state];
  customerListDispatcher = customerListDispatcher;
  VUE_APP_API_BASE_HOST = process.env.VUE_APP_API_BASE_HOST;
  CUSTOMER_LEVEL_ENUM = CustomerLevel;
  formatCurrency = formatCurrency;
  formatDate = formatDate;

  selectedCustomer: CustomerViewModel = {
    level: CustomerLevel.Ordinary,
    address: '',
    displayName: '',
    email: '',
    id: '',
    phone: ''
  } as CustomerViewModel;

  customerLevelOptions: DropdownOption[] = enumToDropdownOptions(CustomerLevel);

  displayCustomerLevel(value) {
    return this.customerLevelOptions.find((x) => x.value === value)
      ? this.$t(
          this.customerLevelOptions.find((x) => x.value === value)
            ?.text as string
        )
      : '';
  }

  mounted() {
    customerListDispatcher.load();
  }

  updateFilterStatus() {
    customerListDispatcher.load(true);
  }

  remove(id: string) {
    const client = new ApiClientFactory().customerClient();
    client.delete(id).then(
      (success) => {
        if (!success) {
          this.$bvToast.toast('Xóa khách hàng thất bại', {
            title: 'Khách hàng',
            toaster: 'b-toaster-bottom-right',
            variant: 'danger'
          });
          return;
        }
        customerListDispatcher.load();
        // show toast
        this.$bvToast.toast('Xóa khách hàng thành công', {
          title: 'Khách hàng',
          toaster: 'b-toaster-bottom-right',
          variant: 'success'
        });
      },
      (err) => {
        this.$bvToast.toast('Xóa khách hàng thất bại', {
          title: 'Khách hàng',
          toaster: 'b-toaster-bottom-right',
          variant: 'danger'
        });
      }
    );
  }

  edit(id) {
    const editItem = this.listState.items.find((s) => s.id === id);
    if (editItem) {
      this.selectedCustomer = { ...editItem } as CustomerViewModel;
      this.$bvModal.show('edit-customer-modal');
    }
  }

  updateLevel(id: string, level: number) {
    const client = new ApiClientFactory().customerClient();

    const body = {
      id,
      level
    } as UpdateCustomerLevelInput;

    client.updateLevel(body).then(() => {
      customerListDispatcher.load();
      this.$bvToast.toast(
        this.$t('UpdateCustomerLevelSuccessfully').toString(),
        {
          title: this.$t('Customer').toString(),
          toaster: 'b-toaster-bottom-right',
          variant: 'success'
        }
      );
    });
  }

  headers = [
    {
      label: this.$t('Customer'),
      field: 'displayName'
    },
    {
      label: this.$t('Phone'),
      field: 'phone'
    },
    {
      label: this.$t('CustomerLevel'),
      field: 'level',
      width: '20%'
    },
    {
      label: '',
      field: '_action',
      sortable: false,
      width: '1%'
    }
  ];
}
