














































































































import {
  CustomerLevel,
  CustomerProfileInput,
  CustomerViewModel,
  Gender
} from '@/api/api';
import ApiClientFactory from '@/api/apiClientFactory';
import { Vue, Component, Prop } from 'vue-property-decorator';
import {
  BModal,
  BFormInput,
  BRow,
  BCol,
  BContainer,
  BFormGroup,
  BForm,
  BButton,
  BFormTextarea
} from 'bootstrap-vue';
import {
  ValidationProvider,
  ValidationObserver
} from 'vee-validate/dist/vee-validate.full';
import { DEFAULT_PAGE_SIZE } from '@/utility/pagination/pagination';
import { DropdownOption } from '@/utility/dropdowns/dropdownOptions';
import { listVueMixin } from '@/utility/pagination/list-vue.mixin';
import { customerListDispatcher } from './store/customer-list.module';

@Component({
  components: {
    BModal,
    BFormInput,
    BContainer,
    BRow,
    BCol,
    BFormGroup,
    BForm,
    BButton,
    BFormTextarea,
    ValidationProvider,
    ValidationObserver
  },
  mixins: [
    listVueMixin({
      initialInput: {
        itemsPerPage: DEFAULT_PAGE_SIZE,
        searchText: '',
        filterLevel: [] as DropdownOption[]
      },
      dispatcher: customerListDispatcher
    })
  ]
})
export default class EditCustomerModal extends Vue {
  @Prop({ type: Object, required: false })
  customer!: CustomerViewModel;

  customerListDispatcher = customerListDispatcher;

  $refs!: {
    formRules: InstanceType<typeof ValidationProvider>;
  };

  getCustomerProfileInput() {
    return {
      customerLevel: CustomerLevel.Ordinary,
      gender: Gender.Male,
      address: this.customer.address ?? '',
      email: this.customer?.email ?? '',
      fullName: this.customer?.displayName ?? '',
      phone: this.customer?.phone ?? '',
      userId: this.customer?.id ?? ''
    } as CustomerProfileInput;
  }

  onOkClick(bvModalEvent) {
    bvModalEvent.preventDefault();
    this.onSubmit();
  }

  onSubmit() {
    this.$refs.formRules.validate().then((success) => {
      if (success) {
        const clientApi = new ApiClientFactory();
        clientApi
          .customerClient()
          .updateProfile(
            this.getCustomerProfileInput(),
            this.customer?.id ?? ''
          )
          .then((result) => {
            if (result) {
              this.customerListDispatcher.load();
              this.$bvToast.toast('Cập nhật thành công', {
                title: `${this.$t('Customer')}`,
                toaster: 'b-toaster-bottom-right',
                variant: 'success'
              });
              this.$bvModal.hide('edit-customer-modal');
              return;
            }

            this.$bvToast.toast('Cập nhật thất bại', {
              title: `${this.$t('Customer')}`,
              toaster: 'b-toaster-bottom-right',
              variant: 'danger'
            });
          })
          .catch((err) => {
            this.$bvToast.toast('Cập nhật thất bại', {
              title: `${this.$t('Customer')}`,
              toaster: 'b-toaster-bottom-right',
              variant: 'danger'
            });
          });
      }
    });
  }

  onCancel() {
    this.$bvModal.hide('edit-customer-modal');
  }
}
