var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('b-modal',{attrs:{"id":"edit-customer-modal","centered":"","title":((_vm.$t('Edit')) + " " + (_vm.$t('Customer'))),"ok-title":_vm.$t('Save'),"cancel-title":_vm.$t('Back')},on:{"ok":_vm.onOkClick,"cancel":function($event){return _vm.onCancel()}}},[_c('validation-observer',{ref:"formRules"},[_c('b-form',{on:{"submit":function($event){$event.stopPropagation();$event.preventDefault();return _vm.onSubmit($event)}}},[_c('validation-provider',{attrs:{"name":"fullName","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
var failedRules = ref.failedRules;
return [_c('b-form-group',{staticClass:"required",attrs:{"label":_vm.$t('FullName'),"label-for":"customer-fullName","label-cols-sm":"3","label-align-sm":"left","state":errors.length > 0 ? false : null}},[_c('b-form-input',{attrs:{"id":"customer-fullName","required":"","trim":"","type":"text","state":errors.length > 0 ? false : null},model:{value:(_vm.customer.displayName),callback:function ($$v) {_vm.$set(_vm.customer, "displayName", $$v)},expression:"customer.displayName"}}),(failedRules.hasOwnProperty('required'))?_c('small',{staticClass:"text-danger"},[_vm._v(" Vui lòng nhập ")]):_vm._e()],1)]}}])}),_c('validation-provider',{attrs:{"name":"phone","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
var failedRules = ref.failedRules;
return [_c('b-form-group',{staticClass:"required",attrs:{"label":_vm.$t('Phone'),"label-for":"customer-phone","label-cols-sm":"3","label-align-sm":"left","state":errors.length > 0 ? false : null}},[_c('b-form-input',{attrs:{"required":"","trim":"","type":"text","state":errors.length > 0 ? false : null},model:{value:(_vm.customer.phone),callback:function ($$v) {_vm.$set(_vm.customer, "phone", $$v)},expression:"customer.phone"}}),(failedRules.hasOwnProperty('required'))?_c('small',{staticClass:"text-danger"},[_vm._v(" Vui lòng nhập ")]):_vm._e()],1)]}}])}),_c('b-form-group',{attrs:{"label":_vm.$t('Email'),"label-for":"customer-email","label-cols-sm":"3","label-align-sm":"left"}},[_c('b-form-input',{attrs:{"trim":"","type":"text"},model:{value:(_vm.customer.email),callback:function ($$v) {_vm.$set(_vm.customer, "email", $$v)},expression:"customer.email"}})],1),_c('validation-provider',{attrs:{"name":"address","rules":"","mode":"lazy"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label":_vm.$t('Address'),"label-for":"customer-address","label-cols-sm":"3","label-align-sm":"left","state":errors.length > 0 ? false : null}},[_c('b-form-textarea',{attrs:{"id":"customerAddress","placeholder":_vm.$t('Address1'),"required":true,"state":errors.length > 0 ? false : null},model:{value:(_vm.customer.address),callback:function ($$v) {_vm.$set(_vm.customer, "address", $$v)},expression:"customer.address"}})],1),(errors.length > 0)?_c('small',{staticClass:"text-danger"},[_vm._v(" "+_vm._s(errors[0])+" ")]):_vm._e()]}}])}),_c('b-button',{staticClass:"sr-only",attrs:{"type":"submit"}},[_vm._v("submit")])],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }